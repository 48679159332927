// @flow
import React from 'react'
import Story from 'storybook/Story'
import { graphql } from 'gatsby'
import Layout from 'containers/Layout'
import styled from 'styled-components'
import Meta from 'theme/atoms/Meta'

type Props = {
  location: {
    pathname: string
    key: string
  }
  data: {
    author: {
      story: any
      firstname: string
      lastname: string
      metaDescription: string
      updatedAt: string
      urlKey: string
    }
  }
}

export default (props: Props) => {
  const { author } = props.data
  const { story } = author
  const crumbs = [
    { label: 'Startseite', link: '/' },
    { label: `${author.firstname} ${author.lastname}` },
  ]
  return (
    <Layout breadcrumbs={crumbs} partialStateUpdates={story.partialStateUpdates}>
      <Meta
        pathname={props.location.pathname}
        title={`${author.firstname} ${author.lastname}`}
        description={author.metaDescription}
        type="profile"
        publishTime={author.updatedAt}
        author={`${author.firstname} ${author.lastname}`}
      />

      <Headline>
        {author.firstname} {author.lastname}
      </Headline>
      <Story key={props.location.key} story={story} />
    </Layout>
  )
}

const Headline = styled.h1`
  text-align: center;
  font-weight: normal;
`

export const query = graphql`
  query($urlKey: String!) {
    author(urlKey: { eq: $urlKey }) {
      story
      firstname
      lastname
      metaDescription
      updatedAt
      urlKey
    }
  }
`
